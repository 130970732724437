import React, { useState, useRef } from 'react';
import Webcam from 'react-webcam';
import jsQR from 'jsqr';
import QRCode from 'qrcode.react';
import './App.css';

function App() {
  const webcamRef = useRef(null);
  const [qrCodeData, setQrCodeData] = useState('');
  const [scanning, setScanning] = useState(false);

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    if (imageSrc) {
      const image = new Image();
      image.onload = () => {
        const canvas = document.createElement('canvas');
        const scale = 2; // ここで拡大率を設定
        canvas.width = image.width * scale;
        canvas.height = image.height * scale;
        const ctx = canvas.getContext('2d');
        ctx.scale(scale, scale);
        ctx.drawImage(image, 0, 0);
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const code = jsQR(imageData.data, imageData.width, imageData.height);
        if (code) {
          setQrCodeData(code.data);
          setScanning(false);
        }
      };
      image.src = imageSrc;
    }
  };

  return (
    <div className='app-container'>
      <Webcam
        audio={false}
        height={300}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        width={300}
        videoConstraints={{
          facingMode: "environment"
        }}
      />
      <button onClick={() => { setQrCodeData(''); setScanning(true); capture(); }}>Scan QR Code</button>
      {scanning && <p>Scanning...</p>}
      {qrCodeData && (
        <div>
          <p className='left-text'>Scanned QR Code:</p>
          <div>
            <QRCode value={qrCodeData} />
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
